import logo from './logo.svg';
import akif from './akif.png'
import './App.css';

function App() {
  return (
    <div className="App">
      <header className="App-header">

        <div>
          <img src={akif} id="akifpng" className="App-logo" alt="logo" />
        </div>

        <h1>
          Portföy Emlak
        </h1>
        <h2>
          Bakımdayız En Yakın Vakitte Hizmetinizdeyiz!
        </h2>


      </header>
      <body>
        <audio src="https://cdn.pixabay.com/download/audio/2023/04/07/audio_2dcfd1398e.mp3?filename=dreamy-piano-ambient-117998.mp3" autoplay loop></audio>
      </body>
    </div>
  );
}

export default App;
